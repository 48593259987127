import { FC } from 'react';
import Carousel from 'react-multi-carousel';
import { useMediaQuery } from 'react-responsive';
import { Container } from 'reactstrap';

import 'react-multi-carousel/lib/styles.css';
import SubNosotros from './subComponents/subnosotros.component';
import Title from './subComponents/title.component';

const Nosotros: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  return (
    <section id="nosotros" className="ftco-section bg-light" style={{ padding: '3em 0' }}>
      <Title title="Nuestra Familia" />
      <Container>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay={false}
          autoPlaySpeed={2500}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 1,
              partialVisibilityGutter: 0
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 0
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 1,
              partialVisibilityGutter: 0
            }
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          // showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <SubNosotros
            image="otr"
            isTablet={isTablet}
            isMobile={isMobile}
            inverse={false}
            titleLabel="Nosotros"
            message="En RE/MAX Cuore, le ponemos el corazón.
Nuestra misión va más allá de Mudarte; se trata de construir historias, cumplir sueños y acompañarte en cada paso del camino. Nos apasiona brindar un servicio cálido y personalizado, donde cada detalle cuenta y cada decisión se toma pensando en vos.
"
          />
          <SubNosotros
            isTablet={isTablet}
            isMobile={isMobile}
            inverse={true}
            image="ofi"
            titleLabel="Para nuestros agentes somos"
            message="Un lugar para desarrollarse y alcanzar metas, con formación continua, líderes que brindan apoyo en cada etapa y un equipo sólido. Una comunidad unida por valores compartidos que fortalecen el trabajo en equipo. "
          />
          <SubNosotros
            image="fam"
            isMobile={isMobile}
            isTablet={isTablet}
            inverse={false}
            titleLabel="Para nuestros clientes somos"
            message="Compromiso, confianza y atención personalizada. Contamos con un equipo de agentes altamente capacitados y profesionales matriculados en el sector. Nos enfocamos en construir relaciones duraderas en lugar de limitarse a simples transacciones, consolidándonos como un equipo líder y destacado en ventas."
          />
        </Carousel>
      </Container>
    </section>
  );
};

export default Nosotros;
