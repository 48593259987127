import { FC } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';
import { Sidetab, Widget } from '@typeform/embed-react';
import '@typeform/embed/build/css/widget.css';
import 'react-multi-carousel/lib/styles.css';

import { OFICINA } from '../../config/constants';
import { azulRemax, rojoRemaxAlt } from '../../styled/const';
const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
  font-family: 'Gotham Medium';
  text-align: center;
  margin-bottom: 3px;
`;

const DescItems = styled.strong`
  color: #324a6d;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
`;

const ContainerItems = styled(Container)`
  text-align: center;
`;

const Formulario: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({
  isMobile,
  isTablet,
  isDesktop
}) => {
  return (
    <section id="formulario" style={{ padding: '1em 0', backgroundColor: azulRemax }}>
      <ContainerItems className="mt-5 mb-5">
        <Title>
          Unite a la familia <strong> {`${OFICINA}.`}</strong>
        </Title>
        <DescItems>
          Buscamos personas apasionadas, con o sin experiencia en el sector, que quieran nuevos desafíos. Completá el
          formulario con tus datos y nuestro equipo se pondrá en contacto para que juntos construyamos el próximo
          capítulo de tu carrera
        </DescItems>
        <iframe
          src="https://xzljvd0t68b.typeform.com/to/B0fbb6HS"
          title="Unite a la familia"
          style={{ width: '100%', height: '600px' }}
          className="mt-5 mb-5"
        ></iframe>
        {/* <div data-tf-widget="<form-id>"></div> */}
        {/* <Widget
          id="pHSRcW5X"
          style={{ width: '100%', height: isMobile ? '1000px' : '700px', marginTop: '5em' }}
          className="my-form"
        /> */}
        {/* <Sidetab id="pHSRcW5X" buttonText="Se parte de nuestra familia" /> */}
        {/* <script src="//embed.typeform.com/next/embed.js"></script> */}
      </ContainerItems>
    </section>
  );
};
export default Formulario;

// https://form.typeform.com/to/pHSRcW5X?typeform-embed-id=31278889168670343&typeform-embed=embed-widget&typeform-source=remax-premium.com.ar&typeform-medium=snippet&typeform-medium-version=next&typeform-embed-handles-redirect=1&typeform-embed-no-heading=true
