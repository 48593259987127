import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { useScroll } from 'framer-motion';
import { FC, useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { Button, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { config } from '../config/config';
import { Agent } from '../models/Agent';
import { getAgents } from '../services/agents.services';
import { setAgents } from '../stores/agentsSlice';
import { setKey } from '../stores/sharedSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
import { OFICINA } from '../config/constants';
import Intro from '../componentes/sumate/intro.component';
import Beneficios from '../componentes/sumate/beneficios.component';
import Formulario from '../componentes/sumate/sumate.component';
import Oportunidades from '../componentes/sumate/oportunidades.component';

// helper para obtener parametros
// import './home.scss';

//   position: absolute;
//   bottom: ${props => props.$size * (props.$row - 1)}px;
//   right: ${props => props.$size * (props.$col - 1)}px;
//   width: ${props => props.$size}px;
//   height: ${props => props.$size}px;
//   background-color: rgba(255, 255, 255, ${props => props.$alpha}); /* Color blanco semitransparente */
//   border-radius: 0px;
//   z-index: 3; /* Asegura que esté por encima del overlay */
const Sumate: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setKey('Sumate'));
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  const titulo = config.TITULO;
  const { scrollYProgress } = useScroll();
  return (
    <>
      <ScrollToTop smooth component={<ArrowCircleUpIcon />} style={{ right: '60px' }} />
      <Beneficios isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
      <Intro isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
      <Oportunidades isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
      <Formulario isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet} />
    </>
  );
};

export default Sumate;
