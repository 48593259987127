import { FC } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

import 'react-multi-carousel/lib/styles.css';
import { OFICINA } from '../../config/constants';
import { rojoRemaxAlt } from '../../styled/const';
const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #000;
  font-family: 'Gotham Medium';
  text-align: center;
  margin-bottom: 3px;
`;
const TitleItems = styled.h4`
  font-family: 'Poppins';
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #000;
`;
const DescItems = styled.strong`
  color: #324a6d;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
  word-spacing: 0em;
`;

const ContainerItems = styled(Container)`
  text-align: center;
`;

const Beneficios: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({
  isMobile,
  isTablet,
  isDesktop
}) => {
  return (
    <section id="beneficios" className="bg-white" style={{ padding: '1em 0' }}>
      <Container className="mt-5 mb-5">
        <Title>
          Filosofía <strong> {`${OFICINA}.`}</strong>
        </Title>
        <Row>
          <Col lg={3} md={6} sm={12} className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}>
            <ContainerItems>
              <div style={{ fontSize: '45px', color: rojoRemaxAlt }}>
                <EmojiEventsOutlinedIcon fontSize="large" scale={15} />
              </div>
              <TitleItems>Calidad de servicio</TitleItems>
              <DescItems>Deseo genuino de servir ayudar al cliente a concretar sus sueños.</DescItems>
            </ContainerItems>
          </Col>
          <Col lg={3} md={6} sm={12} className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}>
            <ContainerItems>
              <div style={{ fontSize: '45px', color: rojoRemaxAlt }}>
                <LanguageOutlinedIcon fontSize="large" scale={15} />
              </div>
              <TitleItems>RE/MAX Internacional</TitleItems>
              <DescItems>
                Formamos parte de la red internacional de bienes raíces más extensa y reconocida a nivel global,
                especializada en la compra y venta de propiedades.
              </DescItems>
            </ContainerItems>
          </Col>
          <Col lg={3} md={6} sm={12} className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}>
            <ContainerItems>
              <div style={{ fontSize: '45px', color: rojoRemaxAlt }}>
                <Diversity3OutlinedIcon fontSize="large" scale={15} />
              </div>
              <TitleItems>Cultura CUORE</TitleItems>
              <DescItems>
                Priorizamos los vínculos humanos, construyendo relaciones de confianza que trascienden las transacciones
              </DescItems>
            </ContainerItems>
          </Col>
          <Col lg={3} md={6} sm={12} className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}>
            <ContainerItems>
              <div style={{ fontSize: '45px', color: rojoRemaxAlt }}>
                <SupportAgentOutlinedIcon fontSize="large" scale={15} />
              </div>
              <TitleItems> Equipo comprometido</TitleItems>
              <DescItems>
                Contamos con un Staff multidisciplinario altamente capacitado y comprometido con ayudarte a alcanzar tus
                metas y desafiarte a hacer la milla exta.
              </DescItems>
            </ContainerItems>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Beneficios;
