import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';

import 'react-multi-carousel/lib/styles.css';
import { rojoRemaxAlt } from '../../styled/const';

const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #000;
  font-family: 'Gotham Medium';
`;

const iconStyle = { color: rojoRemaxAlt, margin: '0px 10px 0px 0px' };

const imgStyle = {
  width: '100%',
  height: 'auto',
  // maxWidth: '300px',
  margin: '10px',
  objectFit: 'cover' as 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
};

const SubNosotros: FC<{
  isTablet: boolean;
  isMobile: boolean;
  titleLabel: string;
  message: string;
  inverse: boolean;
  image: 'fam' | 'ofi' | 'otr';
}> = ({ isMobile, isTablet, titleLabel, message, inverse, image }) => {
  const listStyle = { fontSize: '19px' };
  return (
    <section className="bg-light">
      <Container>
        <Row>
          {!isMobile && (
            <Col
              md={{ order: inverse ? 2 : 1, size: 6 }}
              className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
            >
              <Row>
                {image === 'fam' && (
                  <Col sm={12} className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container">
                    <img src={`/img/familia.jpg`} alt="Familia Remax Cuore" className="img-fluid" style={imgStyle} />
                  </Col>
                )}
                {image === 'ofi' && (
                  <Col sm={12} className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container">
                    <img src={`/img/nosotros.jpeg`} alt="Nosotros Remax Cuore" className="img-fluid" style={imgStyle} />
                  </Col>
                )}
                {image === 'otr' && (
                  <>
                    <Col
                      md={6}
                      lg={6}
                      sm={6}
                      className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
                    >
                      <img src={`/img/remaxcuore-local.jpeg`} alt="Imagen 1" className="img-fluid" style={imgStyle} />
                      <img src={`/img/brokers.jpeg`} alt="Imagen 2" className="img-fluid" style={imgStyle} />
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      sm={6}
                      className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
                    >
                      <img src={`/img/agente1.jpeg`} alt="Imagen 3" className="img-fluid" style={imgStyle} />
                      <img src={`/img/staff.jpeg`} alt="Imagen 4" className="img-fluid" style={imgStyle} />
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          )}
          <Col
            // sm={{ order: inverse ? 1 : 2, size: 12 }}
            // md={{ order: inverse ? 1 : 2, size: 12 }}
            // lg={{ order: inverse ? 2 : 1, size: 6 }}
            md={{ order: inverse ? 1 : 2, size: 6 }}
            className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}
          >
            <Title>{titleLabel}</Title>

            <div>
              {image === 'otr' && (
                <p style={{ color: '#324A6D;', textAlign: 'center', fontSize: 'large' }}>
                  <strong>
                    En RE/MAX Cuore, le ponemos el corazón. Nuestra misión va más allá de Mudarte; se trata de construir
                    historias, cumplir sueños y acompañarte en cada paso del camino. Nos apasiona brindar un servicio
                    cálido y personalizado, donde cada detalle cuenta y cada decisión se toma pensando en vos. <br />
                    <br />
                    Siendo parte de la red inmobiliaria más grande del mundo y con un equipo comprometido con la
                    excelencia, calidad, transparencia y cuidado en cada paso. Porque entendemos que no se trata de
                    casas, sino de hogares.
                    <br />
                    <br />
                    Conta con nosotros para ayudarte a encontrar el lugar perfecto donde comenzar tu próxima etapa.
                  </strong>
                </p>
              )}
              {(image === 'fam' || image === 'ofi') && (
                <p style={{ color: '#324A6D;', textAlign: 'center', fontSize: 'large' }}>
                  <strong>{message}</strong>
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default SubNosotros;
